@import '../constants/';

.paymentMethodModal {
  h2, h3 {
    font-weight: 400;
  }

  h3 {
    margin-bottom: 0;
  }

}

.cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.paymentMethodCard {
  border: 1px solid $grey;
  padding: 1.2rem;
  position: relative;
  overflow: visible;

  &.selectedCard {
    border: 2px solid $primary;
  }

  &.defaultMethod {
    margin-top: 1rem;
  }

  .defaultBadge {
    padding: .4rem;
    background-color: $accent;
    position: absolute;
    top: -1rem;
    left: 1.5rem;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }

  .cardNumber {
    font-weight: 600;
    margin: 0;
  }
  .expDate {
    margin-top: .5rem;
    font-size: 14px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    
    button {
      font-size: 14px;
    }

    .default, .expired {
      width: 145px;
      padding: .5rem;
      white-space: nowrap;
    }

    .expired {
      border-color: $grey;
      color: $grey;
      pointer-events: none;

      &:hover {
        border-color: $grey;
        background-color: $white;
        color: $grey;
      }
    }
  }
}
.separator {
  margin-top: 1rem;
  border-top: 1px solid $grey;
}
.addCardDescription {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}
.newCardButton {
  width: 100%;
}