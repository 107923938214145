@import '../constants/';

.guestsSection {

}

.guests {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.guestOption {
  padding: 1.5rem;
  border: 1px solid $grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;

  &.selected {
    border: 1.5px solid $primary ;
    background-color: #ecedf0;
    pointer-events: none;
  }

  img {
    width: 24px;
  }
}
.guest {
  padding-top: 1rem;
  margin-bottom: 1.5rem;
  border-top: 1px solid $grey;

  .guestForm {
    display: none;
  }

  &.activeGuest {
    .arrowIcon {
      transform: rotate(180deg);
      transition: all 0.2s ease;
    }
    .guestForm {
      display: block;
      margin-top: .5rem;
    }
  }
}

.guestHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint-down(lg) {
    margin-bottom: 2rem;
  }

  .headerRight {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  p {
    font-weight: 600;
  }
  h2, p {
    margin: 0;
  }
}

.inputsRow {
  display: flex;
  gap: 1rem;
}
.radioGroupTitle {
  font-weight: 600;
}
.radioBoxOptions {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: .5rem;

  @include breakpoint-down(lg) {
    flex-direction: column;
  }

  label {
    font-weight: 400;
  }
}

