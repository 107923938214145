@import '../constants';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #404D6A99;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @include breakpoint-down(sm) {
    align-items: flex-end;
  }
}

.modalContent {
  background: $white;
  padding: 3rem;
  position: relative;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @include breakpoint-down(sm) {
    max-width: none;
    width: 100%;
    padding: 1rem;
  }
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: .5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: $primary;
}
