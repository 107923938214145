@import '../constants';

.accountSubmenu {
  ul {
    overflow-x: scroll;
    display: flex;
    padding: 0;
    gap: 2rem;
    padding-top: 2px;
    padding-bottom: 20px; 
    @include breakpoint-down(lg) {
      padding-top: 0;
    }

    
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

.accountSubmenuContainer {
  margin: 0 auto;
  @include breakpoint-up(lg) {
    @include make-max-widths-container-width();
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-weight: 400;
  }
}

.accountSubmenuLink {
  position: relative;
  white-space: nowrap;
  font-weight: 600;
  color: $inactive-menu-item;

  &.active {
    color: $primary;
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      bottom: -20px;
      left: 0;
      @include breakpoint-down(lg) {
        bottom: -15px; 
      }
    }
  }

  @include breakpoint-down(lg) {
    font-size: 14px;
  }
}