@import '../constants/';

.PaymentMethodCard {
  border: 1px solid #404d6a33;
  padding: 1.5rem 1.2rem;

  @include breakpoint-up(lg) {
    max-width: 430px;
  }

  h2 {
    margin: 0;
    font-weight: 400;
  }

  .description {

    &.filled {
      font-weight: 600;
    }
  }

  .button {
    margin-top: 2rem;
    width: 100%;
    &.filled {
      margin-top: 1rem;
      @include breakpoint-up(lg) {
        width: auto;
      }
    }
  }
}
