@import '../constants/';

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  .tabs {
    display: flex;
    gap: 2.5rem;

    .tabButton {
      position: relative;
      white-space: nowrap;
      font-family: basefont;
      font-weight: 600;
      font-size: 16px;
      color: $inactive-menu-item;
    
      &.active {
        color: $primary;
        &:after {
          content: "";
          width: 100%;
          height: 2px;
          background: $primary;
          position: absolute;
          bottom: -20px;
          left: 0;
          @include breakpoint-down(lg) {
            bottom: -15px; 
          }
        }
      }
    
      @include breakpoint-down(lg) {
        font-size: 14px;
      }
    }
  }
}
.appointmentsList {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.appointment {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid $grey;
  width: 100%;

  .noAppointment {
    justify-content: space-between;
    // height: 268px;
  }

  h2 {
    margin: 0;
  }

  @include breakpoint-up(md) {
    width: 432px;
  }
}

.infoItem {
  display: flex;
  gap: 1rem;
  align-items: center;

  p {
    margin: 0;
  }

  img {
    width: 24px;
  }
}

.serviceDetails {
  align-items: flex-start;
}

.pastAppointmentsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;

  .pastAppointmentCard {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid $grey;
    padding: 2rem;
    align-items: flex-start;

    @include breakpoint-down(lg) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .thirdCol {
    text-align: right;
    display: block;

    @include breakpoint-down(lg) {
      display: flex;
      margin-top: 1rem;
      width: 100%;
    }
  }

  .addOns {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;

    li {
      list-style-type: "+ ";
      
    }
  }
}

.manageAppointmentModal, .cancelAppointmentModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2, p {
    margin: 0;
  }

  p {
    line-height: 24px;
  }

  .contactItem {
    display: flex;
    gap: 1rem;
    align-items: center;

    a {
      text-decoration: underline;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $grey;
    margin: 1rem 0;
  }
}

.bookAgainDisabled {
  pointer-events: none;
}

.header {
  margin-top: 0;
}