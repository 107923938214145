@import '../constants/';

.form {
@include breakpoint-up(md) {
  max-width: 350px;
}
}
.button {
  margin-top: 1rem;

  @include breakpoint-down(md) {
    width: 100%;
  }
}