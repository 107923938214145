@import '../constants/';

.appointment {
  display: flex;
  gap: 1.5rem;

  @include breakpoint-down(xl) {
    flex-direction: column;
  }
}

.leftCol {
  @include breakpoint-up(xl) {
    width: 50%;
  }
  @include breakpoint-down(md) {
    align-self: center;
    max-width: 100%;
  }
}

.rightCol {
  border-top: 1px solid $grey;
  width: 100%;
  font-weight: 400;

  @include breakpoint-down(lg) {
    border-top: none;
  }

  & button {
    @include breakpoint-down(md) {
      text-wrap: auto;
      height: auto !important;

      @supports (-webkit-touch-callout: none) {
        word-wrap: break-word;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
  }
}

.calendar {
  font-family: basefont !important;
  background-color: #ecedf0 !important;
  padding: 1rem !important;
  border: none !important;
  font-size: 16px !important;
  color: $primary !important;

  :global .react-calendar__tile {
    border-radius: 50%;
    color: $primary;
    width: 43px;
    max-width: 43px;
    height: 43px;
    padding: 0;

    &.react-calendar__tile--now {
      background-color: transparent;
      border: 1px solid $primary;

      &:hover {
        background-color: #e6e6e6;
      }

      &.react-calendar__tile--active {
        color: $white;
      }
    }
    &.react-calendar__month-view__days__day--weekend {
      color: $primary;

      &:disabled {
        color: #ababab;
      }

      &.react-calendar__tile--active {
        color: $white;
      }
    }
  }

  :global .react-calendar__navigation__label {
    color: $primary;
    font-weight: 600;
  }

  :global .react-calendar__month-view__days {
    justify-content: space-between;
  }

  :global .react-calendar__tile--active {
    background-color: $primary !important;
    color: #fff;
    font-size: 16px;
  }

  :global .react-calendar__year-view__months__month,
  :global .react-calendar__decade-view__years__year {
    padding: 0 !important;
  }
}

.preferencesHeader {
  display: flex;
  align-items: center;
  gap: .5rem;
  p {
    font-weight: 600;
  }
}

.therapistsSelect {
  margin-top: -1px;

  .option {
    border-bottom: 1px solid $grey;
  }
}

.callUs {
  padding: .5rem;
  font-weight: 600;
  border: 1px solid $grey;
  font-size: 14px;
  margin-top: 1rem;
}

.timeSlots {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .slot {
    padding: .5rem;
    width: 90px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid $primary;
    text-align: center;
    cursor: pointer;

    &:hover, &.selected {
      background-color: $primary;
      color: $white;
    }
  }
}

.expandingGuestBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: .5rem;
  border-top: 1px solid $grey;

  .guestName {
    margin: 0;
  }

  &.expanded {
    background-color: #ecedf0;
    img {
      transform: rotate(180deg);
    }
  }

  .expandingContentRight {
    display: flex;
    align-items: center;
    gap: .3rem;
  }
}

.nearbyBoutiqueBtn {
  margin-top: 1rem;
}