@import '../constants/';


.heading {
  border-bottom: 1px solid $grey;
  margin: 1rem 0 2rem;
  padding-bottom: .3rem;

  h2 {
    margin: 0;
  }
}
.preference {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .preferenceForm {
    flex: 2;
  }
  .preferenceLayout {
    position: relative;
    flex: 1;
  }
}
.messageForm {
  margin-top: 2rem;
}
textarea {
  border-color: $grey;
  background-color: $white;
  font-family: basefont;
  font-size: 16px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 96px;
  max-height: 140px;
  color: $primary;
}

.actions {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.preferenceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    border-bottom: 1px solid $primary;
    padding-bottom: .75rem;
    width: 100%;
  }
  .dropdown {
    width: 35%;
  }
}

.circle {
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  border: 1px solid $primary;
}
