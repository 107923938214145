@import '../constants/';

.locationSearch {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 1;

  @include breakpoint-down(md) {
    top: 56px;
  }
}
.mapContainer {
  width: 100%;
  height: 100%;

  @include breakpoint-down(md) {
    height: 50%;
  }
}

.inputContainer {
  position: absolute;
  z-index: 1;
  width: 100%;

  @include breakpoint-down(md) {
    top: 1rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 95%;
  }

  @include breakpoint-up(md) {
    top: 4rem;
    left: 2rem;
    width: 432px;
  }

  input {
    padding: 1rem;
    width: 100%;
    border: 1px solid $grey;
    background-color: $white;

    @include breakpoint-down(sm) {
      font-size: 16px;
    }
  }
.inputButtons {
  position: absolute;
  right: .5rem;
  top: .7rem;
  display: flex;
}
  .geoButton {
    border-left: 1px solid $grey;
  }

  .clearButton {
    font-size: 1.2rem;
  }
}

.boutiqueList {
  margin-top: 1.5rem;
  border: 1px solid $grey;
  background-color: $white;
  padding: 0 1rem;
  overflow: scroll;
  height: 100%;
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 35%;
  left: 0;
  padding-bottom: 100%;

  @include breakpoint-up(md) {
    top: 7rem;
    left: 2rem;
    width: 432px;
    padding: 0 1rem;
    height: 75%;
  }

  ul {
    padding: 0;
  }
  &.noResults {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.boutiqueItem {
  padding: 1rem;
  border-bottom: 1px solid $grey;

  .boutiqueName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    img {
      transform: rotate(-90deg);
    }
    h3 {
      text-decoration: underline;
      margin: 0;
    }
  }


  .buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .viewMapButton {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 16px;
    text-transform: uppercase;
  }
}

.noLocations {
  align-self: center;

  h3 {
    text-decoration: underline;
  }
}

.boutiqueInfo {
  .separator {
    height: 1px;
    width: 100%;
    background-color: $grey;
    margin-top: 1rem;
  }
  .closeInfoButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  .phone, .clock {
    margin-top: 1rem;
    display: flex;
    gap: .5rem;
    align-items: center;
    font-size: 18px;
  }
  .clock span {
    font-size: 16px;
    font-weight: 600;
  }
  .weeklyHours {
    padding-left: 2rem;
    @include breakpoint-up(md) {
      max-width: 70%;
    }
  }
  .workHours {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 600;
    }
  }
}