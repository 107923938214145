.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 32px;
  background: #404D6A80;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.switchLabel .switchButton {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 28px;
  height: 28px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchLabel:active .switchButton {
  width: 60px;
}

.switchCheckbox:disabled + .switchLabel {
  background: #404d6a4e;
  cursor: not-allowed;
}

