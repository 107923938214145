@import '../constants/';

.booking {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-direction: column;
}

.formSteps {
  width: unset;
  padding: 1rem;
  border-bottom: 1px solid $grey;

  @include breakpoint-up(lg) {
    width: 80%;
    align-self: center;
  }
}

.controls {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  border-top: 1px solid $grey;
  background-color: $white;

  .controlButton,
  .controlButtons {
    width: 100%;
    white-space: nowrap;
  }

  .nextStep {
    font-weight: 600;
    display: none;
  }

  .controlsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .controlButtons {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.pregnancyModalContent {
  h2 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }

  .contactsRow {
    display: flex;
    gap: 1rem;
  }

  .boutiqueName {
    font-weight: 600;
  }

  .pregnancyModalContacts {
    margin-bottom: 1rem;
  }
}

.restoreDataBookingModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    margin: 0;
  }
}

.enhancementsPopUpOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #404D6A99;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  z-index: 1000;
  align-items: flex-end;
}

.enhancementsPopUp {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 70vh;
  background-color: $white;
  display: flex;
  flex-direction: column;

  .popUpHeaderContainer {
    flex: 0 0 auto;
    padding: 1rem;
    background-color: $white;
  }

  .enhancementsPopUpContent {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 100px;
  }

  .popUpHeaderContainer {
    border-bottom: 1px solid $grey;
  }

  .enhancementsPopUpHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
    }
  }

  .popUpActionsContainer {
    flex: 0 0 auto;
    padding: 1rem;
    background-color: $white;
    border-top: 1px solid $grey;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .popUpActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @include breakpoint-up(md) {
      width: 40%;
    }

    @include breakpoint-up(lg) {
      width: 30%;
    }
  }

  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: $primary;
  }

  .enhancementsPopUpGroup {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}

.hubspotModalContent {
  img {
    width: 24px;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    margin-top: 0;
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  button {
    margin-top: 1rem;
    width: 100%;
  }
}

.stepper {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey;
  border-bottom: none;

  .step,
  .groupTitle {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid $grey;
    color: $inactive-menu-item;
    cursor: not-allowed;
    flex-direction: column;

    &.active {
      font-weight: 600;
      color: $primary;
      cursor: pointer;
      border-bottom: none;
    }

    &.highlighted {
      font-weight: 600;
      color: $primary;
      cursor: pointer;
    }
  }

  .step {
    padding: 0;
  }

  .group {
    &.active {
      .step {
        border-bottom: none;
      }
    }
  }

  .group {
    .groupSteps {
      .step {
        padding: 1rem;
        flex-direction: row;

        &.active {
          font-weight: 400;
        }
      }

    }

    .groupTitle {
      position: relative;
      cursor: pointer;

      &.active {
        background-color: #ecedf0;

        &::after {
          content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM4MjQgMi40ODUyOUw4LjQ3NzkgOS41NTc3MUM4LjM2Njc0IDkuNjU3MDQgOC4yMjA3MSA5LjcwODE4IDguMDcxODggOS42OTk5MkM3LjkyMzA1IDkuNjkxNjUgNy43ODM1OCA5LjYyNDY0IDcuNjg0MTIgOS41MTM2MkwwLjYxMTY5MSAxLjYwOTE0QzAuNTE4Mzk5IDEuNDk3MTYgMC40NzIyMTMgMS4zNTMzNCAwLjQ4Mjg2NCAxLjIwNzk4QzAuNDkzNTE0IDEuMDYyNjIgMC41NjAxNyAwLjkyNzA3MyAwLjY2ODc4OCAwLjgyOTg4OEMwLjc3NzQwNSAwLjczMjcwNCAwLjkxOTUwNSAwLjY4MTQ3MyAxLjA2NTE1IDAuNjg2OTg5QzEuMjEwNzkgMC42OTI1MDUgMS4zNDg2MSAwLjc1NDMzOCAxLjQ0OTU3IDAuODU5NDZMOC4xNDcyMSA4LjM0NDA2TDE1LjYzMjcgMS42NDc0MUMxNS43NDQ3IDEuNTU0MTIgMTUuODg4NSAxLjUwNzkzIDE2LjAzMzkgMS41MTg1OEMxNi4xNzkyIDEuNTI5MjMgMTYuMzE0OCAxLjU5NTg5IDE2LjQxMTkgMS43MDQ1MUMxNi41MDkxIDEuODEzMTMgMTYuNTYwNCAxLjk1NTIzIDE2LjU1NDggMi4xMDA4N0MxNi41NDkzIDIuMjQ2NTEgMTYuNDg3NSAyLjM4NDMzIDE2LjM4MjQgMi40ODUyOVoiIGZpbGw9IiM0MDRENkEiLz4KPC9zdmc+Cg==');
          position: absolute;
          right: 1rem;
        }
      }

      &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMzk5MDkgMS4xMDA5TDguODk5MSA4LjYwMDkxQzkuMDA0NDMgOC43MDYzOCA5LjA2MzYgOC44NDkzNCA5LjA2MzYgOC45OTg0MUM5LjA2MzYgOS4xNDc0NyA5LjAwNDQzIDkuMjkwNDQgOC44OTkxIDkuMzk1OTFMMS4zOTkwOSAxNi44OTU5QzEuMjkyNDYgMTYuOTk1MyAxLjE1MTQzIDE3LjA0OTQgMS4wMDU3IDE3LjA0NjhDMC44NTk5NzcgMTcuMDQ0MiAwLjcyMDkzOCAxNi45ODUyIDAuNjE3ODc4IDE2Ljg4MjFDMC41MTQ4MTggMTYuNzc5MSAwLjQ1NTc4NCAxNi42NCAwLjQ1MzIxMyAxNi40OTQzQzAuNDUwNjQxIDE2LjM0ODYgMC41MDQ3MzQgMTYuMjA3NSAwLjYwNDA5NCAxNi4xMDA5TDcuNzA1NjYgOC45OTg0MUwwLjYwNDA5NSAxLjg5NTkxQzAuNTA0NzM1IDEuNzg5MjggMC40NTA2NDIgMS42NDgyNCAwLjQ1MzIxMyAxLjUwMjUxQzAuNDU1Nzg0IDEuMzU2NzkgMC41MTQ4MTkgMS4yMTc3NSAwLjYxNzg3OCAxLjExNDY5QzAuNzIwOTM4IDEuMDExNjMgMC44NTk5NzcgMC45NTI1OTcgMS4wMDU3IDAuOTUwMDI0QzEuMTUxNDMgMC45NDc0NTMgMS4yOTI0NiAxLjAwMTU0IDEuMzk5MDkgMS4xMDA5WiIgZmlsbD0iIzQwNEQ2QSIvPgo8L3N2Zz4K');
        position: absolute;
        right: 1rem;
      }
    }
  }
}

.whoIsComing {
  display: flex;
  flex-direction: column;
}

.stepTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;

  p {
    margin: 0;
  }
}

.boutique {
  padding: 1rem;
  border-bottom: 1px solid $grey;
  cursor: pointer;

  .boutiqueTitle {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: 600;
    color: $primary;
  }

  .address {
    font-weight: 400;
    color: $primary;
    margin-bottom: 0;
  }
}