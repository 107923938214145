@import '../constants/';

.dashboardCard {
  // flex: 1 1 calc(50% - 1.5rem);
  // max-width: calc(50% - 1.5rem); // Ensure the maximum width matches the flex basis
  flex: 1 1 calc(50% - .75rem);
  max-width: calc(50% - .75rem);
  border: 1px solid #404D6A33;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @include breakpoint-down(md) {
    flex: 1 1 100%;
    max-width: 100%;
  }

  p {
    margin-top: 0;
  }

  img {
    height: 30px;
  }
}
.headerContainer {
  h2 {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: .5rem;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}