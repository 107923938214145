@import '../constants/';

.form {
  max-width: 100%;
  margin: 0 auto;

  @include breakpoint-up(md) {
    max-width: 60%;
  }

  @include breakpoint-up(lg) {
    max-width: 45%;
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 32px;
    font-weight: 400;
    @include breakpoint-down(lg) {
      font-size: 24px;
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      margin-right: 1rem;
      margin-bottom: 0;
      width: 24px;
      height: 24px;
      border-radius: 0;
    }

    a {
      display: inline-block;
      text-decoration: underline;
    }

    label {
      font-weight: 400;
    }
  }

  .button {
    width: 100%;
    margin-top: 2rem;
  }

  .haveAccountLink {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }
}

.successModal {
  img {
    width: 24px;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    margin-top: 0;
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  button {
    margin-top: 1rem;
    width: 100%;
  }
}

.locationChangeModal {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  p, h2 {
    margin: 0;
  }

  .cancelButton {
    width: 60%;
  }
}