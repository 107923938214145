@import '../constants/';

.pressure {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.pressureOption {
  padding: 1rem;
  border: 1px solid $grey;
  width: 100%;

  .pressureTitle {
    font-weight: 600;
  }

  &.selected {
    background-color: #ecedf0;
    border-color: $primary;
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}