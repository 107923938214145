@import '../constants/';

.toast {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: .7rem;
  padding: .9rem .6rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: 20px;
  z-index: 1002;
  animation: slide-in 0.5s ease-out;
  border: 1px solid $primary;
  background: $white;

  @include breakpoint-up(md) {
    max-width: 350px;
    left: unset;
    top: 184px;
    bottom: unset;
  }

  p {
    margin: 0;
  }


  // in case we want to add different styles based on the type of the toast
  // &.success {
  //   background-color: #4caf50;
  // }

  // &.error {
  //   background-color: #f44336;
  // }

  .closeButton {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
