@import '../constants/';

.cancelMembership {
  h1 {
    font-weight: 400;
  }
}
.backButton {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  font-weight: 600;
  img {
    transform: rotate(90deg);
  }
}
.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;

  @include breakpoint-down(md) {
    flex-direction: column;
  }
}
.card {
  flex: 1 1 calc(50% - 1.5rem);
  max-width: calc(50% - 1.5rem);
  border: 1px solid #404D6A33;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint-down(md) {
    flex: 1 1 100%;
    max-width: 100%;
  }

  h2 {
    font-weight: 400;
    margin-top: 0;
  }
  p {
    margin-top: 0;
    line-height: 24px;
  }

  .button {
    margin-top: 1rem;
  }
}

.cancelModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-weight: 400;
    margin: 0;
  }
  ul {
    padding-left: 1.5rem;
    margin-top: 0;
    li {
      list-style: disc;
      line-height: 24px;
    }
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .closeButton {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 1rem;
    font-size: 16px;
  }
  .cancelButton {
    font-size: 16px;
  }
}
.error {
  color: $input-error;
}

.pauseModal {
  padding: 1.5rem;
}