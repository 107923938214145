@import '../constants/';

.tabs {
  display: flex;
  width: 100%;

  .tab {
    padding: .5rem;
    border-bottom: 1px solid $grey;
    width: 100%;
    font-weight: 400;
    text-align: center;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid $primary;
      font-weight: 600;
    }
  }
}

.nonMemberOptions {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  margin: 2rem 0;

  &.disabled {
    .priceOption {
      p, h2 {
        color: #acacac;
      }

      .disabledBtn {
        background: #e5e5e5;
        border: none;
        color: #acacac;
        cursor: not-allowed;
      }
    }
  }
}

.priceOption, .memberOption {
  padding: 1.5rem;
  border: 1px solid $grey;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;

  p {
    font-weight: 600;
    margin-top: 0;
  }
}

.priceOption {
  @include breakpoint-down(sm) {
    padding: 1rem .5rem;
  }
}

.memberOption {
  width: 200px;
  position: relative;
  h2 {
    margin: 0;
  }
  p {
    margin-bottom: .3rem;
  }

  &.selected {
    background-color: #ecedf0;
    border-color: $primary;
  }

  &.disabled {
    color: #acacac;
    cursor: not-allowed;
  }

  @include breakpoint-down(lg) {
   width: 45%; 
  }
}

.membersDurations {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.yourMembership {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $primary;
  color: $white;
  padding: 0.2rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.promoBanner {
  padding: 1rem;
  background: rgba(236, 237, 240, 1);
  width: 100%;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
}

.memberCta {
  margin-top: auto;
  @include breakpoint-down(sm) {
    padding: 0;
  }
}