@import '../constants/';

.bookingCheckout {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding-bottom: 3rem;

  @include breakpoint-down(lg) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  h2, p {
    margin: 0;
  }
}

.contentBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.separator {
  height: 1px;
  width: 100%;
  background: $grey;
  align-self: center;

  @include breakpoint-down(sm) {
    width: 100vw;
  }
}

.orderSeparator {
  @include breakpoint-up(lg) {
    display: none;
  }
}

.paymentDetails, .bookingDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 450px;
}

.inputWithAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid $grey;

  p {
    margin: 0;
  }

  input {
    width: 100%;
    background: transparent;
    border: none;
    margin-right: 1rem;
    appearance: none;
    font-size: 16px;
    
    &:focus {
      outline: none;
    }
  }
}

.promo {
  background-color: #ecedf0;
  padding: .5rem;
}

.promoCodeCopy {
  text-transform: uppercase;
  font-weight: 600;
}

.promoInput {
  color: $primary;
  font-size: 16px;
  font-family: basefont;
}

.promoValue {
  display: flex;
  gap: .5rem;
}

.promoError {
  color: $input-error;
}

.priceDetails, .subTotal, .total {
  display: flex;
  justify-content: space-between;
}

.subContentBlock {
  padding: 1rem;
}

.frequency {
  margin-top: .5rem;
}

.checkbox {
  margin-top: 0;
}

.error {
  color: $input-error;
}

.notes {

  .expandingBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0;
    cursor: pointer;

    h3 {
      margin: 0;
    }
  }
  
  textarea {
    border-color: $grey;
    background-color: $white;
    font-family: basefont;
    font-size: 16px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    color: $primary;
  }
}
.priceDetailsGroup {
  .separator {
    margin: 0;
  }

  h2 {
    margin-bottom: 1.5rem;
  }
}
.expandingGuestBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border-top: 1px solid $grey;

  .guestName {
    margin: 0;
  }

  &.expanded {
    background-color: #ecedf0;
    img {
      transform: rotate(180deg);
    }
  }

  .expandingContentRight {
    display: flex;
    align-items: center;
    gap: .3rem;
  }
}

.groupDisclaimer {
  font-size: 14px;
  margin-bottom: 0;
}

.promotionalValue {
  font-weight: 600;
}

.addOn {
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
}

.packageInfo, .therapistInfo {
  display: flex;
  align-items: center;
  gap: .5rem;

  img {
    width: 24px;
  }
}

.charactersLimit {
  margin-top: -.5rem !important;
  font-size: 12px;
  font-weight: 600;
}

.cancellationModal {
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0;
  }
}

.chooseYourPrice {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.priceOptionContainer {
  padding: 1rem;
  border: 1px solid $grey;

  &.selectedPrice {
    background-color: #ecedf0;
    border-color: $primary;
  }
}
.priceOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .priceDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    p {
      font-weight: 600;
    }
  }

  .membershipDetails {
    p {
      font-weight: 600;
    }
  }

  .memberPriceDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .membershipDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .memberPriceToggleDetails {
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;

      &.expanded {
        img {
          transform: rotate(180deg);
        }
      }

    p {
      font-size: 12px;
      text-decoration: underline;
    }
  }
}

.membershipDetailsContent {
  margin-top: 1rem;

  p {
    font-size: 12px;
  }

  button {
    font-size: 12px;
  }
}

.iframeModal {
  padding: 0; 
  
  iframe {
    width: 100%;
    min-height: 515px;
    border: none;
  } 
}

.groupPromoDisclaimer {
  border: 1px dashed $grey;
  padding: .5rem 1rem;
  p {
    font-size: 14px;
    margin: 0;
  }
}