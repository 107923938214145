@mixin breakpoint-up($size) {
  $breakpoint: map-get($grid-breakpoints, $size);
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  $breakpoint: map-get($grid-breakpoints, $size);
  @media (max-width: ($breakpoint - 1px)) {
    @content;
  }
}

@mixin make-max-widths-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include breakpoint-up($breakpoint) {
      max-width: $container-max-width;
    }
  }
}
