
.errors {
  display: flex;
  flex-direction: column;

  .error {
    span {
      font-weight: 600;
    }
  }
}