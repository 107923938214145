@import '../constants/';

@import '../constants/';

.selectEnhancements {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @include breakpoint-down(lg) {
    justify-content: space-between;
  }

  .enhancement {
    border: 1px solid $grey;
    padding: 1.5rem;

    @include breakpoint-down(lg) {
      width: 47%;
      padding: 1rem;
    }

    &.selected {
      background-color: #ecedf0;
      border-color: $primary;

      .description {
        font-weight: 400;
        @include breakpoint-down(lg) {
          display: block;
          overflow: hidden;
        }
      }

      
    }

    &.locked {
      background-color: #ecedf0;
      border-color: $primary;
    }

    &.restricted {
      color: #acacac;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .price {
        margin-top: 0;
        font-weight: 400;
      }
      .title {
        font-weight: 600;
        margin-top: 0;
      }
    }

    .description {
      margin-bottom: 0;
      font-weight: 400;

      @include breakpoint-down(lg) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 0rem;
      }

      &.showFullDescription {
        @include breakpoint-down(lg) {
          -webkit-line-clamp: unset;
        }
      }
    }


    @include breakpoint-up(lg) {
      width: 318px;
    }

    .benefitsTitle {
      font-style: italic;
      font-weight: 600;
    }

    .benefitsDetails {
      font-weight: 400;
    }

    .arrowButton {
      padding: 0;
      margin-top: 1rem;
      &.active {
        transform: rotate(180deg);
        margin-top: 0;
      }
    }

    &.popUpItem {
      @include breakpoint-up(md) {
        width: 300px;
      }
    }
  }

  .noEnhancement {
    font-weight: 400;
  }
}