@import '../constants/';

.stepper {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey;
  border-bottom: none;

  .step, .groupTitle {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid $grey;
    color: $inactive-menu-item;
    cursor: not-allowed;

    &.active {
      font-weight: 600;
      color: $primary;
      cursor: pointer;
    }

    &.highlighted {
      font-weight: 600;
      color: $primary;
      cursor: pointer;
    }
  }

  .group {
    .groupSteps {
      .step {
        &.active {
          font-weight: 400;
        }
      }

    }

    .groupTitle {
      position: relative;
      cursor: pointer;

      &.active {
        background-color: #ecedf0;
        &::after {
          content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM4MjQgMi40ODUyOUw4LjQ3NzkgOS41NTc3MUM4LjM2Njc0IDkuNjU3MDQgOC4yMjA3MSA5LjcwODE4IDguMDcxODggOS42OTk5MkM3LjkyMzA1IDkuNjkxNjUgNy43ODM1OCA5LjYyNDY0IDcuNjg0MTIgOS41MTM2MkwwLjYxMTY5MSAxLjYwOTE0QzAuNTE4Mzk5IDEuNDk3MTYgMC40NzIyMTMgMS4zNTMzNCAwLjQ4Mjg2NCAxLjIwNzk4QzAuNDkzNTE0IDEuMDYyNjIgMC41NjAxNyAwLjkyNzA3MyAwLjY2ODc4OCAwLjgyOTg4OEMwLjc3NzQwNSAwLjczMjcwNCAwLjkxOTUwNSAwLjY4MTQ3MyAxLjA2NTE1IDAuNjg2OTg5QzEuMjEwNzkgMC42OTI1MDUgMS4zNDg2MSAwLjc1NDMzOCAxLjQ0OTU3IDAuODU5NDZMOC4xNDcyMSA4LjM0NDA2TDE1LjYzMjcgMS42NDc0MUMxNS43NDQ3IDEuNTU0MTIgMTUuODg4NSAxLjUwNzkzIDE2LjAzMzkgMS41MTg1OEMxNi4xNzkyIDEuNTI5MjMgMTYuMzE0OCAxLjU5NTg5IDE2LjQxMTkgMS43MDQ1MUMxNi41MDkxIDEuODEzMTMgMTYuNTYwNCAxLjk1NTIzIDE2LjU1NDggMi4xMDA4N0MxNi41NDkzIDIuMjQ2NTEgMTYuNDg3NSAyLjM4NDMzIDE2LjM4MjQgMi40ODUyOVoiIGZpbGw9IiM0MDRENkEiLz4KPC9zdmc+Cg==');
          position: absolute;
          right: 1rem;
        }
      }

      &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMzk5MDkgMS4xMDA5TDguODk5MSA4LjYwMDkxQzkuMDA0NDMgOC43MDYzOCA5LjA2MzYgOC44NDkzNCA5LjA2MzYgOC45OTg0MUM5LjA2MzYgOS4xNDc0NyA5LjAwNDQzIDkuMjkwNDQgOC44OTkxIDkuMzk1OTFMMS4zOTkwOSAxNi44OTU5QzEuMjkyNDYgMTYuOTk1MyAxLjE1MTQzIDE3LjA0OTQgMS4wMDU3IDE3LjA0NjhDMC44NTk5NzcgMTcuMDQ0MiAwLjcyMDkzOCAxNi45ODUyIDAuNjE3ODc4IDE2Ljg4MjFDMC41MTQ4MTggMTYuNzc5MSAwLjQ1NTc4NCAxNi42NCAwLjQ1MzIxMyAxNi40OTQzQzAuNDUwNjQxIDE2LjM0ODYgMC41MDQ3MzQgMTYuMjA3NSAwLjYwNDA5NCAxNi4xMDA5TDcuNzA1NjYgOC45OTg0MUwwLjYwNDA5NSAxLjg5NTkxQzAuNTA0NzM1IDEuNzg5MjggMC40NTA2NDIgMS42NDgyNCAwLjQ1MzIxMyAxLjUwMjUxQzAuNDU1Nzg0IDEuMzU2NzkgMC41MTQ4MTkgMS4yMTc3NSAwLjYxNzg3OCAxLjExNDY5QzAuNzIwOTM4IDEuMDExNjMgMC44NTk5NzcgMC45NTI1OTcgMS4wMDU3IDAuOTUwMDI0QzEuMTUxNDMgMC45NDc0NTMgMS4yOTI0NiAxLjAwMTU0IDEuMzk5MDkgMS4xMDA5WiIgZmlsbD0iIzQwNEQ2QSIvPgo8L3N2Zz4K');
        position: absolute;
        right: 1rem;
      }
    }
  }
}

.whoIsComing {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
  }
}

.boutique {
  padding: 1rem;
  border-bottom: 1px solid $grey;
  cursor: pointer;
  .boutiqueTitle {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: 600;
    color: $primary;
  }

  .address {
    font-weight: 400;
    color: $primary;
    margin-bottom: 0;
  }
}