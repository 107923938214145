@import '../constants/';

.orderDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include breakpoint-up(lg) {
    border: 1px solid $grey;
    padding: 1rem;

    &.highlighted {
      background-color: #ecedf0;
      border-color: $primary;
    }
  }
}

.item {
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  .itemInfo {
    display: flex;
    flex-direction: column;
    gap: .4rem;
  }

  p {
    margin: 0;
  }

  img {
    width: 24px;
  }
}
@include breakpoint-down(lg) {
  .separator {
    height: 1px;
    width: 100%;
    background: $grey;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
