@import '../constants/';

.switch {
  margin: 1rem 0;

  .switchContainer {
    @include breakpoint-up(lg) {
      max-width: 350px;
    }
  
    .switchTitle {
      font-weight: 600;
      margin: 0;
      color: #acacac;
  }
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .switchSubtitle {
    max-width: 250px;
    margin: 0;
  }
}