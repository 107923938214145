@import './styles/globals.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: $primary;
  font-family: basefont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style-type: none;
}

button {
  background: none;
  border: none;
}
