@import '../constants';

.mainMenu {
  height: $main-menu-height;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 4px 0 12px;
  border-bottom: 1px solid #404D6A33;
  background: $white;
  z-index: 2;
  @include breakpoint-down(lg) {
    height: $main-menu-height-sm;
    padding: 4px 0;
  }
}

.mainMenuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  @include breakpoint-down(sm) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.mainMenuLinksWrapper,
.mainMenuNavLinks {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navLink {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.mainMenuUserLinks {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  @include breakpoint-down(lg) {
    gap: .5rem;
  }

  a {
    width: 24px;
  }
}

.mainMenuNavLinks {
  @include breakpoint-down(lg) {
    display: none;
  }

  &.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; 
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1000; 
    padding: $main-menu-height 1rem 1rem;
    gap: 1rem;
    overflow-y: auto;
  }
}

.navCta {
  @include breakpoint-down(lg) {
    height: 32px;
    padding: .5rem .8rem;
  }
}

//hamburger
.menuToggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1100; // Ensure it's above the menu
  width: 50px;
  height: 50px;
  user-select: none;

  @include breakpoint-up(lg) {
    display: none;
  }

  span {
    display: block;
    position: relative;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    z-index: 1;
    margin-bottom: 5px;
    border-radius: 10px;
    background: $primary;
    width: 25px;
    height: 3px;
  }

  &.open {
    span {
      &:nth-child(1) {
        transform: rotate(45deg) translate(0, 6px); 
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(-4px, -4px); 
      }
    }
  }
}

.accountDesktopLink {
  @include breakpoint-down(lg) {
    display: none;
  }
}

.accountLinkSeparator {
  display: none;
  @include breakpoint-down(lg) {
    display: block;
    width: 100vw;
    height: 1px;
    background-color: $grey;
    position: absolute;
    top: 0;
    left: -1rem;
    right: 0;
  }
}
.accountMobileLinkWrapper {
  position: relative;
}
.accountMobileLink {
  display: none;
  @include breakpoint-down(lg) {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    width: 100%;

    svg {
      width: 24px;
    }
  }
}