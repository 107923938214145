@import '../constants/';

.form {

  @include breakpoint-up(lg) {
    margin-top: 1rem;
    max-width: 600px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .fieldGroup {
    display: flex;
    gap: 1rem;
  }
  .button {
    width: 158px;
    font-size: 14px;
    padding: 1rem;

    @include breakpoint-down(sm) {
      margin-top: 1rem;
    }
  }
}
