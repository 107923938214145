@import '../constants/';

.purchaseConfirmation {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding-bottom: 3rem;

  @include breakpoint-down(lg) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }


  h3 {
    margin: 0;
    font-size: 24px;
  }
  .message {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .separator {
      display: flex;
      height: 1px;
      width: 100%;
      background: $grey;
      align-self: center;
      margin: 0;
    }
  }

  .orderDetails, .message {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 450px;
  }

  .orderDetails {
    width: 100%;
}
.separator {
  height: 1px;
  width: 100%;
  background: $grey;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;

  @include breakpoint-down(sm) {
    width: 100vw;
  }
  @include breakpoint-up(lg) {
    display: none;
  }
}
}

.preferencesModal {
  max-height: 60%;
  overflow-y: scroll;

  @include breakpoint-up(lg) {
    min-width: 660px;
  }
}