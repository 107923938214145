@import '../constants/';

.form {
  max-width: 100%;
  margin: 0 auto;

  @include breakpoint-up(md) {
    max-width: 60%;
  }

  @include breakpoint-up(lg) {
    max-width: 45%;
  }

  h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 32px;
    font-weight: 400;
    @include breakpoint-down(lg) {
      font-size: 24px;
    }
  }

  .passwordTip {
    p {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
    ul {
      margin-top: .5rem;
      padding-left: 1.5rem;
    } 
    
    li {
      list-style-type: disc;
      font-size: 12px;
    }
  }
  .button {
    margin-top: 1rem;
    width: 100%;
  }
  .newAccountLink {
    font-weight: 600;
    text-align: center;
  
    a {
      text-decoration: underline;
    }
  
  }
}