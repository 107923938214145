@import '../constants/';


.noMemberships {
  .header {
    h3 {
      font-size: 24px;
      font-weight: 400;
      margin-top: 0;
    }
  }

  .membershipPerks {
    border-bottom: 1px solid #404d6a33;
    border-top: 1px solid #404d6a33;
    padding: 1.5rem 0;
    margin-top: 2rem;

    h3 {
      font-size: 24px;
      margin-top: 0;
    }
  }

  .membershipBenefits {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  
    .membershipBenefit {
      width: calc(50% - 0.5rem);
      @include breakpoint-up(lg) {
        width: 204px;
      }

      img {
        width: 32px;
      }
    }
  }

  .selectMembership {
    display: flex;
    gap: 1rem;
  
    @include breakpoint-down(lg) {
      flex-direction: column;
      gap: 0;
    }
  }
  
  .membershipColumn {
    
    h3 {
      font-weight: 400;
      font-size: 24px;
    }
  
    .membershipTitle {
      margin: 0 0 1.5rem;
    }
  
  }

  .membershipCard {
    width: 432px;
    border: 1px solid $grey;
    padding: 1.5rem 1rem;
    margin-bottom: 2rem;
    position: relative;
    border-radius: 2px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
  
    @include breakpoint-down(md) {
      width: 100%;
      margin-bottom: 1rem;
    }

    .membershipFrequency {
      margin: 0;
    }
  
    .membershipContentRight {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: .5rem;
    }
  
    .membershipPrice {
      font-family: headfont;
      font-size: 24px;
      font-weight: 300;
    }
  
    .nonMemberPrice {
      font-size: 14px;
      margin-top: 0;
    }

    .discount {
      font-weight: 600;
    }
  }
}

.locationContainer {
  max-width: 432px;
  @include breakpoint-down(md) {
    max-width: 100%;
  }
  margin: 2rem 0;
  .locationPickerContainer {
    display: flex;
    justify-content: space-between;
  }

  .boutiqueCardLocation {
    cursor: pointer;
    position: relative;
    background-color: #ecedf0;
    margin-top: .5rem;
    border: 1px solid #404D6A;
    padding: 1rem;
    h4, p {
      margin: 0;
    }
    p {
      margin-top: .5rem;
    }

    .arrowIcon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: rotate(-90deg);
    }
  }
}
