@import '../constants';

.submenu {
  position: fixed;
  top: $main-menu-height;
  left: 0;
  height: $sub-menu-height;
  width: 100vw;
  border-bottom: 1px solid #404D6A33;
  background-color: $white;
  z-index: 1;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @include breakpoint-down(lg) {
    top: $main-menu-height-sm;
    height: $sub-menu-height-sm;
  }

  ul {
    display: flex;
    padding: 0;
    gap: 2rem;
    padding-top: 2px;
    @include breakpoint-down(lg) {
      padding-top: 0;
      padding-left: 1rem;
    }

    li {
      white-space: nowrap;
    }
  }
}

.submenuContainer {
  margin: 0 auto;
  @include breakpoint-up(md) {
    max-width: 70%;
  }
  @include breakpoint-up(lg) {
    @include make-max-widths-container-width();
    padding: 0 1rem;
  }
  display: flex;
  justify-content: space-between;
}

.submenuLink {
  position: relative;
  font-weight: 600;
  color: $inactive-menu-item;
  &.active {
    color: $primary;
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      bottom: -20px;
      left: 0;
      @include breakpoint-down(lg) {
        bottom: -14px;
        @supports (-webkit-touch-callout: none) {
          bottom: -12px;
        }
      }
    }
  }
  @include breakpoint-down(lg) {
    font-size: 14px;
  }
}

.submenuLogout {
  font-family: basefont;
  font-weight: 600;
  color: $primary;
  cursor: pointer;
  font-size: 16px;
  text-transform: capitalize;
  text-decoration: underline;
  line-height: 24px;
  padding: 0;
  white-space: nowrap;
  margin-left: 1rem;


  @include breakpoint-down(lg) {
    padding-right: 1rem;
  }
}
