@import '../constants/';

.radioBoxContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  .input {
    display: flex;
    align-items: center;
    padding: 1rem;
    border: 1px solid $grey;
    cursor: pointer;
    position: relative;
    width: 100%;

    &.selected {
      border-bottom: none;
    }
  }
  .radioInput {
    // display: none; // Hide the input, only show the custom UI
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }
  
  .radioLabel {
    flex-grow: 1;
    cursor: pointer;
  }

  .radioIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 1rem;
  }
}

.subOptions {
  // padding: 1rem;
  border: 1px solid $grey;
  border-top: none;
}

.radioOption {
  display: flex;
  padding: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
  
  .radioInputSub {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .radioLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 1rem;
  }
}

.error {
  color: $input-error;
  font-size: 0.8rem;
}
