@import '../constants/';

.footerLegal {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid $grey;
}

.footerLegalContainer {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  padding: 1rem 0;
  font-size: 14px;

  @include breakpoint-down(md) {
    flex-direction: column;
  }
}

.footerLinks {
  ul {
    margin: 0;
    display: flex;
    gap: 1rem;
    padding-left: 1rem;
  
    @include breakpoint-down(md) {
      flex-direction: column;
    }
  }

}

.footerCopyright {
  p {
    margin: 0;
  }
  @include breakpoint-down(md) {
    margin-top: 1rem;
    padding-left: 1rem;
  }
}