@import '../constants/';

$main-ease: cubic-bezier(.65,.05,.36,1);
$color-focus: $primary;

.checkboxContainer {
  display: flex;
  align-items: center;
  margin: 2rem 0 1rem;
}

.checkbox {
  display: none;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 2.5rem;
  position: relative;
  color: $primary;

  a {
    text-decoration: underline;
  }
}

.checkboxLabel::before {
  content: "";
  position: absolute;
  left: 0;
  top: -4px;
  width: 20px;
  height: 20px;
  border: 2px solid $primary;
  background-color: white;
  transition: background-color 0.2s $main-ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox:checked + .checkboxLabel::before {
  background-color: $primary;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMi4zNTQgMC44NTQwMjhMNC4zNTQwMyA4Ljg1NDAzQzQuMzA3NTkgOC45MDA1MiA0LjI1MjQ1IDguOTM3NCA0LjE5MTc1IDguOTYyNTZDNC4xMzEwNSA4Ljk4NzcyIDQuMDY1OTkgOS4wMDA2NyA0LjAwMDI4IDkuMDAwNjdDMy45MzQ1NyA5LjAwMDY3IDMuODY5NTEgOC45ODc3MiAzLjgwODgxIDguOTYyNTZDMy43NDgxMSA4LjkzNzQgMy42OTI5NiA4LjkwMDUyIDMuNjQ2NTMgOC44NTQwM0wwLjE0NjUyOCA1LjM1NDAzQzAuMDUyNzA3NyA1LjI2MDIxIDAgNS4xMzI5NiAwIDUuMDAwMjhDMCA0Ljg2NzYgMC4wNTI3MDc3IDQuNzQwMzUgMC4xNDY1MjggNC42NDY1M0MwLjI0MDM0OCA0LjU1MjcxIDAuMzY3NTk2IDQuNSAwLjUwMDI3OCA0LjVDMC42MzI5NiA0LjUgMC43NjAyMDggNC41NTI3MSAwLjg1NDAyOCA0LjY0NjUzTDQuMDAwMjggNy43OTM0TDExLjY0NjUgMC4xNDY1MjhDMTEuNzQwMyAwLjA1MjcwNzQgMTEuODY3NiAtOS44ODU1N2UtMTAgMTIuMDAwMyAwQzEyLjEzMyA5Ljg4NTU4ZS0xMCAxMi4yNjAyIDAuMDUyNzA3NCAxMi4zNTQgMC4xNDY1MjhDMTIuNDQ3OCAwLjI0MDM0OCAxMi41MDA2IDAuMzY3NTk2IDEyLjUwMDYgMC41MDAyNzhDMTIuNTAwNiAwLjYzMjk2IDEyLjQ0NzggMC43NjAyMDggMTIuMzU0IDAuODU0MDI4WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
}
