// colors
$primary: #404D6A;
$accent: #e1e5ef;
$grey: #d8d9d9;
$white: #fefcf5;
$input-error: #FF3B2F;
$inactive-menu-item: #8f98a8;

$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

$grid-breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
  xxl: $breakpoint-xxl
);

$container-max-widths: (
  xs: 100%, 
  sm: 540px,
  md: 720px,
  lg: 940px,
  xl: 1140px,
  xxl: 1320px
);

$main-menu-height: 64px;
$main-menu-height-sm: 56px;
$sub-menu-height: 56px;
$sub-menu-height-sm: 48px;