@import '../constants/';

.booking {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @include breakpoint-down(lg) {
    flex-direction: column;
  }
}

.formSteps {
  width: 100%;
  @include breakpoint-up(lg) {
    width: unset;
    width: 60%;
  }
}

.controls {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  border-top: 1px solid $grey;
  background-color: $white;

  @include breakpoint-down(lg) {
    .controlButton, .controlButtons {
      width: 100%;
      white-space: nowrap;
    }
  }

  .nextStep {
    font-weight: 600;

    @include breakpoint-down(lg) {
      display: none;
    }
  }

  .controlsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .controlButtons {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}
.pregnancyModalContent {
  h2 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  .contactsRow {
    display: flex;
    gap: 1rem;
  }
  .boutiqueName {
    font-weight: 600;
  }
  .pregnancyModalContacts {
    margin-bottom: 1rem;
  }
}

.restoreDataBookingModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    margin: 0;
  }
}

.enhancementsPopUpOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #404D6A99;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @include breakpoint-down(sm) {
    align-items: flex-end;
  }
}

.enhancementsPopUp {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 70vh;
  background-color: $white;
  display: flex;
  flex-direction: column;

  .popUpHeaderContainer {
    flex: 0 0 auto;
    padding: 1rem;
    background-color: $white;
  }

  .enhancementsPopUpContent {
    flex: 1 1 auto; 
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 100px;
  }

  .popUpHeaderContainer {
    border-bottom: 1px solid $grey;
  } 
  .enhancementsPopUpHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
    }
  }

  .popUpActionsContainer {
    flex: 0 0 auto;
    padding: 1rem;
    background-color: $white;
    border-top: 1px solid $grey;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .popUpActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @include breakpoint-up(md) {
      width: 40%;
    }
    @include breakpoint-up(lg) {
      width: 30%;
    }
  }

  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: $primary;
  }

  .enhancementsPopUpGroup {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.hubspotModalContent {
    img {
      width: 24px;
    }
    h2 {
      font-size: 24px;
      font-weight: 400;
      margin-top: 0;
    }
  
    ul {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    li {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    button {
      margin-top: 1rem;
      width: 100%;
    }
}