@import '../constants/';

$main-ease: cubic-bezier(.65,.05,.36,1);
$color-focus: $primary;

.input {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  @include breakpoint-down(lg) {
    margin-bottom: 2rem;
  }

  .suggestionsList {
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 1000;
    max-height: 12rem; // Limit the height to approximately 5-7 items
    overflow-y: auto; // Make it scrollable
    background: $white;
    border: 1px solid $primary;
    border-top: none;
    position: absolute;
    width: 100%;

    li {
      cursor: pointer;
      transition: background 0.2s $main-ease;
      padding: 1rem .5rem;
  
      &:hover,
      &.selected {
        background: #404D6A33;
      }
    }
  }

  .arrowIcon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%); // Ensure it stays centered vertically
    color: $primary;
    pointer-events: none; 
  }

  &.entryErrorAC {  
    .error {
      position: absolute;
      bottom: -1.5rem; 
    }
  }
}

.input input {
  height: 3rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid $primary;
  font-size: 16px;
  font-family: 'basefont';
  transition: .1s $main-ease;
  outline: none;
  background: none;
  color: $primary;

  &:disabled {
    opacity: 0.4;
  }
}

.input label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: $primary;
  text-align: left;
  transition: .2s $main-ease;
  font-size: 1rem;
  pointer-events: none;
}

.input {
  &.entryError {
    label, .mask {
      top: 35%;
    }
    
  }
}

.input input:not(:placeholder-shown) ~ label,
.input input:focus ~ label {
  top: 0;
  left: 0;
  font-size: 12px;
  color: $color-focus;

  @include breakpoint-down(lg) {
    top: -5px;
  }
}

.input .underline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transition: transform 0.2s $main-ease;
  border-bottom: 1px solid $primary;
}

.input input:focus ~ .underline {
  transform: scaleX(1);
}

.input input:hover {
  border-bottom: 1px solid $color-focus;
}

// hide number input arrows
input[type="number"] {
  -moz-appearance: textfield;
}

.passwordInput {
  padding-right: 2rem;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hidePassword {
  position: absolute;
  right: 0;
  top: 20%;
  cursor: pointer;
}

.entryError,
.entrySuccess {
  .underline {
    display: none;
  }
}

.input .mask {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: $color-focus;
  font-size: 1rem;
  pointer-events: none;
  transition: opacity 0.2s $main-ease;

  input:focus ~ & {
    opacity: 1;
  }

  input:not(:placeholder-shown) ~ & {
    opacity: 0;
  }
}
