@import '../constants/';

.form {
  max-width: 100%;
  margin: 0 auto;

  @include breakpoint-up(md) {
    max-width: 60%;
  }

  @include breakpoint-up(lg) {
    max-width: 45%;
  }

  h1 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 32px;
    font-weight: 400;
    @include breakpoint-down(lg) {
      font-size: 24px;
    }
  }

  .button {
    margin-top: 1rem;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    p {
      margin-bottom: .5rem;
      margin-top: 0;
      font-weight: 600;
    }
    a {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.modalContent {
  p {
    margin: 2rem 0;
  }
}