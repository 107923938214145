@import '../constants/';

.dashboard {

  h1 {
    font-weight: 400;
    margin-top: 0;
  }

  p {
    margin-bottom: 8px;
  }
  .cardGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;

    @include breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .cta {
    margin-top: 1.5rem;
  }

  .upcomingAppointmentContent,
  .memberCardContent,
  .questionnaireCardContent,
  .giftCardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .viewAppointmentsButton {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: .5rem;
    cursor: pointer;
  }
  .membershipCredits {
    margin-top: .7rem;
    display: flex;
    flex-direction: column;
    gap: .7rem;
  }
  .membershipCreditsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .3rem 0;

    &.filled {
      background-color: $accent;
    }

    p {
      margin: 0;
      font-weight: 600;
      span {
        margin-left: .5rem;
        font-weight: 400;
      }
    }
    .creditsInfoButton {
      cursor: pointer;
    }
  }

  .appointmentDate,
  .membershipSubtitle {
    font-weight: 600;
  }
.upcomingAppointmentInfo {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
  .appointmentTime,
  .appointmentBoutique,
  .appointmentDetails {
    display: flex;
    gap: .5rem;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
    p {
      margin: 0;
    }
  }
  .appointmentDetails {
    align-items: flex-start;
    .appointmentAddOns {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }
  }

  .appointmentCtas,
  .membershipCta {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
  .membershipDetails {
    padding-left: 1.5rem;
    li {
      list-style-type: disc;
    }
  }
  .alignmentBlock {
    height: 1rem;
    width: 100%;
    @include breakpoint-down(md) {
      display: none;
    }
  }

  .boutiqueCardLocation {
    cursor: pointer;
    position: relative;
    background-color: #ecedf0;
    margin-top: 1rem;
    border: 1px solid #404D6A;
    padding: 1rem;
    h4, p {
      margin: 0;
    }
    p {
      margin-top: .5rem;
    }

    .arrowIcon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: rotate(-90deg);
    }
  }

}

.creditsModal {
  .creditsModalTitle {
    font-weight: 400;
}
.creditsModalButton {
    margin-top: 1rem;
  }
}

.purchaseGiftCardButton {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 1rem;

  @include breakpoint-down(md) {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    margin-top: 0;
  }
}

.newSiteCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  p {
    font-size: 18px;
  }
} 

.locationContainer {
  .locationPickerContainer {
    display: flex;
    justify-content: flex-end;
  }
}

.preferencesModal {
  max-height: 60%;
  overflow-y: scroll;

  @include breakpoint-up(lg) {
    min-width: 660px;
  }
}