@import '../constants/';

$main-ease: cubic-bezier(.65,.05,.36,1);
$color-focus: $primary;

.dropdown {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  &Input {
    height: 3rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid $primary;
    font-size: 16px;
    font-family: 'basefont';
    background: none;
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    &:hover {
      border-bottom: 1px solid $color-focus;
    }

    .label {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $primary;
      text-align: left;
      transition: all 0.2s $main-ease;
      font-size: 1rem;
      pointer-events: none;

      &[data-focused='true'] {
        top: 0;
        left: 0;
        font-size: 0.75rem;
        color: $color-focus;
        transform: translateY(0);
      }
    }

    .selectedValue {
      flex-grow: 1;
      text-align: left;
      padding-right: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: .6rem;
    }

    .arrowIcon {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
      transition: transform 0.2s ease-in-out;
      pointer-events: none;

      &.open {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .suggestionsList {
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 1000;
    max-height: 12rem;
    overflow-y: auto;
    background: $white;
    border: 1px solid $primary;
    border-top: none;
    width: 100%;

    li {
      cursor: pointer;
      transition: background 0.2s $main-ease;
      padding: 1rem 0.5rem;

      &:hover,
      &.selected {
        background: #404D6A33;
      }
    }
  }

  .errorMessage {
    position: absolute;
    bottom: -1.5rem;
    font-size: 12px;
    color: $input-error;
  }
}
