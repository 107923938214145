@import '../constants/';

.memberships {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @include breakpoint-down(lg) {
    flex-direction: column;
  }
}

.membershipCard {
  border: 1px solid $grey;
  padding: 1rem;
  padding-bottom: .5rem;

  @include breakpoint-up(md) {
    width: 432px;
  }

  h2 {
    font-weight: 400;
    margin-top: .5rem;
    margin-bottom: .6rem;
  }

  .description {
    font-weight: 600;
    margin: 0;
  }

  .location {
    margin-top: .5rem;
  }

  .membershipCreditsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .2rem 0;
  
    &.filled {
      background-color: $accent;
    }
  
    p {
      margin: 0;
      font-weight: 600;
      span {
        margin-left: .5rem;
        font-weight: 400;
      }
    }
    .creditsInfoButton {
      cursor: pointer;
    }
  }

  .terms {
    margin-bottom: 1.8rem;
  }
  .terminationDate {
    font-weight: 600;
  }

  .cardButton {
    width: 100%;
    display: flex;
    position: relative;
    font-size: 16px;
    color: $primary;
    font-family: basefont;
    padding: 1.1rem 0;
    border-top: 1px solid $grey;
    cursor: pointer;
  &::after {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2Ljg5NzEgMTIuMzk3MUw5LjM5NzE0IDE5Ljg5NzFDOS4yOTA1MSAxOS45OTY1IDkuMTQ5NDggMjAuMDUwNiA5LjAwMzc1IDIwLjA0OEM4Ljg1ODAyIDIwLjA0NTUgOC43MTg5OCAxOS45ODY0IDguNjE1OTIgMTkuODgzNEM4LjUxMjg3IDE5Ljc4MDMgOC40NTM4MyAxOS42NDEzIDguNDUxMjYgMTkuNDk1NUM4LjQ0ODY5IDE5LjM0OTggOC41MDI3OCAxOS4yMDg4IDguNjAyMTQgMTkuMTAyMUwxNS43MDM3IDExLjk5OTZMOC42MDIxNCA0Ljg5NzE0QzguNTAyNzggNC43OTA1MSA4LjQ0ODY5IDQuNjQ5NDcgOC40NTEyNiA0LjUwMzc1QzguNDUzODMgNC4zNTgwMiA4LjUxMjg3IDQuMjE4OTggOC42MTU5MiA0LjExNTkyQzguNzE4OTggNC4wMTI4NiA4Ljg1ODAyIDMuOTUzODMgOS4wMDM3NSAzLjk1MTI2QzkuMTQ5NDggMy45NDg2OSA5LjI5MDUxIDQuMDAyNzggOS4zOTcxNCA0LjEwMjE0TDE2Ljg5NzEgMTEuNjAyMUMxNy4wMDI1IDExLjcwNzYgMTcuMDYxNiAxMS44NTA2IDE3LjA2MTYgMTEuOTk5NkMxNy4wNjE2IDEyLjE0ODcgMTcuMDAyNSAxMi4yOTE3IDE2Ljg5NzEgMTIuMzk3MVoiIGZpbGw9IiM0MDRENkEiLz4KPC9zdmc+Cg==');
    position: absolute;
    right: 0;
  }
  }
}

.pauseModal {
  padding: 1.5rem;
}

.terminationConditions {
  padding-left: 1.2rem;
  li {
    list-style-type: disc;
    margin-bottom: 1rem;
  }
}

.memberCard {
  width: 100%;

  @include breakpoint-up(md) {
    width: 432px;
  }
}
.memberCardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.membershipDetails {
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;

  li {
    list-style-type: disc;
  }
}

.termsLink {
  font-weight: 400;
}