@import '../constants/';

.membershipCredits {
  display: flex;
  flex-direction: column;
  gap: .7rem;
  @include breakpoint-down(md) {
    width: 100%;
  }
}
.membershipCreditsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .3rem;

  &.filled {
    background-color: $accent;
  }

  p {
    margin: 0;
    font-weight: 600;
    span {
      margin-left: .5rem;
      font-weight: 400;
    }
  }
  .creditsInfoButton {
    cursor: pointer;
  }
}

.creditsModal {
  .creditsModalTitle {
    font-weight: 400;
}
.creditsModalButton {
    margin-top: 1rem;
  }

  ul {
    margin: 0;
    padding-left: 1.2rem;
    li {
      list-style: disc;
      margin-bottom: 1rem;
    }

  }
}