@import '../constants/';

.pauseMembership {
  padding: 1rem;
  border: 1px solid $grey;
  @include breakpoint-up(md) {
    width: 432px;
  }
}

.cancelHeading {
  h1 {
    margin-top: 1rem;
  }
}

h2 {
  margin-top: 0;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  font-weight: 600;
  img {
    transform: rotate(90deg);
  }
}

.status, .remainingMonths {
  border: 1px solid $grey;
  padding: 1rem;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
  }
}

.statusHeading {
  display: flex;
  gap: 1rem;
  align-items: center;
  p {
    margin: 0;
    font-weight: 600;
  }
}

.remainingMonths {
  text-align: center;
  font-weight: 600;
}

.termsHeading {
  font-weight: 600;
  margin-top: 1.5rem;
}

.termsList {
  padding-left: 1.2rem;
  li {
    list-style-type: disc;
    padding-left: 0;
  }
}

.actions,
.modalActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  gap: 1rem;

  p {
    margin: 0;
  }
}

.modalActions {
  gap: 1.5rem;
}