@import './constants/_index.scss';

body {
  background-color: $white;
  padding-bottom: 100px !important;
  position: relative;
  min-height: calc(100vh - 50px);

  @include breakpoint-down(lg) {
    min-height: unset;
    padding-bottom: 200px !important;
  }

  -webkit-font-smoothing: antialiased; /* For iOS */
  -moz-osx-font-smoothing: grayscale; /* For macOS */
}

@supports (-webkit-touch-callout: none) {
  /* Styles for iOS devices */
  body {
    letter-spacing: -.75px;
  }
}

.container {
  @include make-max-widths-container-width();
  width: 100%;
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.button {
  background-color: $primary;
  display: inline-block;
  padding: 1rem 1.3rem;
  font-size: 14px;
  font-weight: 600;
  font-family: basefont, 'Helvetica Neue', sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  height: 48px;
  white-space: nowrap;

  @include breakpoint-down(md) {
    font-size: 12px;
  }

  &:hover {
    background-color: #2d364a;
  }

  &.inverted {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;

    &:hover {
      border-color: #2d364a;
    }
  }

  &.large {
    height: 52px;
    width: 100%;
  }

  &.disabled {
    background-color: #e5e5e5;
    color: #acacac;
    cursor: not-allowed;
  }
}

.button__link {
  padding: 0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: $primary;
  font-family: basefont, 'Helvetica Neue', sans-serif;
  cursor: pointer;
}

.button__underline {
  position: relative;
  align-self: center;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: $primary;
  width: fit-content;
  font-family: basefont, 'Helvetica Neue', sans-serif;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    background-color: $primary;
    width: 100%;
    left: 0;
    bottom: -3px;
  }

  &:hover {
    color: #2d364a;
  }
}

.w-full {
  width: 100%;
}


.guest-layout {
  padding-top: $main-menu-height;
  @include breakpoint-down(lg) {
    padding-top: $main-menu-height-sm;
  }
  margin-top: 64px;
}

.blank-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.authenticated-layout {
  padding-top: $main-menu-height + $sub-menu-height;
  @include breakpoint-down(lg) {
    padding-top: calc($main-menu-height-sm + $sub-menu-height-sm / 3);
  }
  margin-top: 64px;
}

.location-picker {
  width: fit-content !important;
  margin-bottom: 0 !important;
  input {
    border: none !important;
  }
  label {
    text-decoration: underline;
    font-weight: 700;
  }

  ul {
    border-top: 1px solid $primary !important;
  }
}

.locked {
  pointer-events: none;
}