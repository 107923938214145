@import '../constants/';

.pageTitle,
.pageDescription {
  text-align: center;
}
.pageTitle {
  margin-top: 0;
}
.perks {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  .perk {
    width: 100%; 
    @include breakpoint-up(md) {
      flex: 1 1 calc(50% - 1rem); 
    }
    @include breakpoint-up(lg) {
      flex: 1 1 calc(33.333% - 1rem); 
      max-width: calc(33.333% - 1rem); 
    }

    h3 {
      font-size: 24px;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    img {
      width: 100%;
    }

    .subTitle {
      font-weight: 600;
      font-size: 18px;
    }

    .description {
      display: none;
      p {
        font-size: 18px;
        line-height: 28px;
      }
      .termsLink {
        font-size: 18px;
        font-weight: 700;
      }

      &.active {
        display: block;
        margin-top: 1rem;
      }
    }

    .expandButton {
      margin-top: 1rem;
      font-family: basefont;
      font-size: 14px;
      display: flex;
      gap: .4rem;
      align-items: center;
      color: $primary;
      text-transform: uppercase;
      font-weight: 600;
      padding: 0;
      img {
        width: 24px;

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .code {
      padding: 0 .5rem 0 1rem;
      font-size: 14px;
      font-weight: 600;
      border: 1px dashed #b1b6c2;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 48px;
    }
  }

  .actions {
    display: flex;
    margin-top: .5rem;
    gap: 1rem;
    width: 100%;
    justify-content: flex-end;

    @include breakpoint-down(md) {
      flex-direction: column;
      gap: 1rem;
    }

    &.hasCode {
      justify-content: space-between;
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;

  .nextStep,
  .prevStep {
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
  .nextStep {

    img {
      transform: rotate(180deg);
    }
  }

  .pages {
    .pageNumber {
      font-size: 18px;
      font-family: basefont;
      color: $primary;
      opacity: .5;
      &.active {
        opacity: 1;
        pointer-events: none;
      }
    }
  }
}