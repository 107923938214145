@import '../constants/';

.selectLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  padding: 0 1rem;
  font-weight: 400;

  &.open {
    img {
      transform: rotate(180deg);
    }
  }
}

.option {
  padding: 1rem;
  margin-left: 1rem;
  cursor: pointer;
  p {
    margin: 0;
    font-weight: 400;
  }

  &.selected {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 600;
    }
  }
  &.separate {
    border-bottom: 1px solid $grey;
  }

  &.disabled {
    opacity: 40%;
  }
}

.disabled {
  .option {
    opacity: 40%;
  }
  .selected {
    opacity: 100%;
  }
}