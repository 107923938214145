@import '../constants/';

.inputFeedback {
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 3px;

  &.success {
    img {
      display: block;
    }
  }

  &.error {
    img {
      display: none;
    }
    color: $input-error;
  }

  &.apiError {
    img {
      display: block !important;
      width: 16px;
    }
  }

  .feedbackText {
    font-size: 12px;
    margin: 0;
    font-weight: 600;
    margin-top: 2px;

    span a {
      text-decoration: underline;
    }
  }
}