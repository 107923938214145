@import '../constants/';

.errorPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;

  img {
    width: 50%;
  }

  @include breakpoint-down(md) {
    flex-direction: column;

    img {
      width: 100%;
    }
  }

  &.customError {
    .content {
      padding: 1rem;
    }
  }

}
.actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}