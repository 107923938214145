@import '../constants/';



.checkout {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding-bottom: 3rem;

  @include breakpoint-down(lg) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  h2, p {
    margin: 0;
  }
}

.contentBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.separator {
  height: 1px;
  width: 100%;
  background: $grey;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;

  @include breakpoint-down(sm) {
    width: 100vw;
  }
}

.orderSeparator {
  @include breakpoint-up(lg) {
    display: none;
  }
}

.paymentDetails, .orderDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 450px;
}

.inputWithAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid $grey;

  p {
    margin: 0;
  }

  input {
    width: 100%;
    background: transparent;
    border: none;
    margin-right: 1rem;
    appearance: none;
    font-size: 16px;
    
    &:focus {
      outline: none;
    }
  }
}

.priceDetails, .subTotal, .total {
  display: flex;
  justify-content: space-between;
}

.frequency {
  margin-top: .5rem;
}

.promo {
  background-color: #ecedf0;
  padding: .5rem;
}

.promoCodeCopy {
  text-transform: uppercase;
  font-weight: 600;
}

.promoInput {
  color: $primary;
  font-size: 16px;
  font-family: basefont;
}

.promoValue {
  display: flex;
  gap: .5rem;
}

.promoError {
  display: flex;
  align-items: center;
  gap: .3rem;
  font-size: 14px;
  color: $input-error;
}

.checkbox {
  margin-top: 0;

  .termsLink {
    text-decoration: underline;
    font-size: 16px;
    font-family: basefont;
    color: $primary;
    cursor: pointer;
    padding: 0;
  
  }
}

.error {
  color: $input-error;
}

.iframeModal {
  padding: 0; 
  
  iframe {
    width: 100%;
    min-height: 515px;
    border: none;
  } 
}