@import '../constants/';

.bookingDetails {
  display: flex;
  flex-direction: column;

  @include breakpoint-up(lg) {
    border: 1px solid $grey;
  }
}

.navigation {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid $grey;

  img {
    transform: rotate(90deg);
  }

  @include breakpoint-down(lg) {
    padding: 0px 0px 20px 0px;
    border: none;
  }
}

.bookingInfo {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include breakpoint-down(lg) {
    padding: 0;
  }
}

.item {
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  .itemInfo {
    display: flex;
    flex-direction: column;
    gap: .4rem;
  }

  p {
    margin: 0;
    margin-top: 3px;
  }

  .promotionalValue {
    margin-top: .5rem;
  }

  img {
    width: 24px;
    height: 24px;
  }
}
@include breakpoint-down(lg) {
  .separator {
    height: 1px;
    width: 100%;
    background: $primary;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-self: center;
  }

  @include breakpoint-down(sm) {
    .separator {
      width: 100vw;
    }
  }
}

.message {
  align-items: flex-start;

  .messageContent {
    margin-top: .7rem;
  }
}

.addOns {
  ul {
    padding: 0;
    margin: 0;
  }
}