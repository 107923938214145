.termsModalContent {
  padding: 0 1rem;
  h2 {
    font-weight: 400;
  }
  p {
    line-height: 24px;
  }

  .termsModalButton {
    margin-top: 3rem;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
  }
}