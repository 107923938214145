@import '../constants/';

.notificationsSettings {
  padding: 1rem 1.5rem;
  border: 1px solid $grey;
  width: fit-content;

  @include breakpoint-down(md) {
    width: 100%;
  }
  .banner {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid $grey;
    max-width: 600px;
    position: relative;
    .bannerTitle {
      display: flex;
      align-items: center;
      gap: .5rem;
      p {
        font-weight: 600;
        margin: 0;
      }
    }

    .bannerDescription {
      margin-top: .5rem;
    }

    @include breakpoint-down(lg) {
      margin-top: 0;
    }

    .closeButton {
      position: absolute;
      top: 1rem;
      right: .5rem;
      background: none;
      border: none;
      font-size: 1.1rem;
      cursor: pointer;
      color: $primary;
    }
  }
  h2 {
    font-weight: 400;
    margin-bottom: 1rem;
    @include breakpoint-down(lg) {
      font-size: 16px;
  }
  }
  .notificationsDescription {
    margin-top: 0;
    margin-bottom: 2rem;
    @include breakpoint-down(lg) {
      font-size: 14px;
  }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $grey;
  }
}