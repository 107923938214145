@import '../constants/';

.loginPage {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include breakpoint-down(lg) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .loginPageContent {
    width: 55%;
    padding-right: 1rem;

    @include breakpoint-down(lg) {
      margin-top: 2rem;
      border-top: 1px solid #404D6A33;
      width: 100%;
      padding-right: 0;
      padding-top: 2rem;
    }

    h2 {
      font-weight: 400;
    }

    .promoImage {
      width: 100%;
      height: 364px;
      object-fit: cover;
    }

    .contentCopy{
      font-size: 18px;
      margin-bottom: 1rem;
    }

    .contentSubCopy {
      margin: 1rem 0 1.5rem;
      font-size: 14px;
    }
  }


form {
  width: 40%;
  padding: 0 1rem;

  @include breakpoint-down(lg) {
    width: 100%;
    padding: 0;
  }

  h2 {
    text-align: left;
    margin-bottom: 1rem;
    margin-top: 0;
    font-size: 32px;
    font-weight: 400;
    @include breakpoint-down(lg) {
      font-size: 24px;
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      margin-right: 1rem;
      margin-bottom: 0;
      width: 24px;
      height: 24px;
      border-radius: 0;
    }

    a {
      
      display: inline-block;
      text-decoration: underline;
    }

    label {
      font-weight: 400;
    }
  }

  .buttonLogin {
    width: 100%;
    margin-top: 1rem;
  }

  .forgotPasswordLink {
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    margin-top: .5rem;
    text-decoration: underline;
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: #404D6A33;
    margin: 1.5rem 0;
  }

  h3 {
    font-size: 32px;
    font-weight: 400;
    margin-top: 0;
  }

  .createAccountLink {
    width: 100%;
  }

  .privacyPolicy {
    font-weight: 700;
    font-size: 12px;

    a {
      text-decoration: underline;
    }
  }
}
}

.newSiteCard {
  margin-top: 1.5rem;
  border: 1px solid $grey;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 24px !important;
  }
  p {
    font-size: 18px;
  }

  .locationButton {
    width: fit-content;
  }
}

.errorMessage {
  display: flex;
  gap: .5rem;
  align-items: center;
  color: $input-error;
}

.sliderArrows {
  display: flex;
  justify-content: space-between;
  @include breakpoint-down(md) {
    display: none;
  }
  .swiperButtonPrev,
  .swiperButtonNext {
    width: 48px;
  }

  .swiperButtonNext {
    img {
      transform: rotate(180deg);
    }
  }
}

.promoSlider {
  :global .swiper-pagination-progressbar {
    background-color: #404d6a80 !important;
    bottom: 1rem;
    top: unset;
    width: 70%;
    right: 0;
    margin: 0 auto;
    height: 1px;

    @include breakpoint-down(md) {
      width: 100%;
    }
  }
  :global .swiper-pagination-progressbar-fill {
    background-color: $primary !important;
    height: 2px;
  }
}