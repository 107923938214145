@import '../constants/';

.balance {
  border-bottom: 1px solid $grey;
  padding: 1.1rem 1rem;
  margin-bottom: 1rem;

  p {
    margin: 0;
  }
}
.form {
  margin-top: .5rem;
}

.button {
  width: 100%;
}