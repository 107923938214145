@import '../constants/';

.updateMembership {

  h1 {
    font-weight: 400;
    margin-bottom: 0;
  }

  .location {
    margin: .5rem 0;
  }

  .backButton {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: fit-content;
    font-weight: 600;
    img {
      transform: rotate(90deg);
    }
  }
}

.membershipsContainer {
  display: flex;
  gap: 1rem;

  @include breakpoint-down(lg) {
    flex-direction: column;
    gap: 0;
  }
}

.membershipColumn {
  
  h2 {
    font-weight: 400;
    margin: 1rem 0 .5rem;
  }

  .membershipTitle {
    margin: 0 0 1.5rem;
  }

}

.membershipCard {
  width: 432px;
  border: 1px solid $grey;
  padding: 1rem;
  margin-bottom: 2rem;
  position: relative;
  border-radius: 2px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;

  @include breakpoint-down(md) {
    width: 100%;
    margin-bottom: 1rem;
  }

  &.active {
    border: 2px solid $primary;
    margin-bottom: 30px;
    background-color: #ecedf0;

    @include breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }

  .membershipContentLeft {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .membershipContentRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: .5rem;
  }

  .currentMembership {
    position: absolute;
    top: -1rem;
    right: 10px;
    background-color: $primary;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
  }

  .membershipFrequency {
    font-weight: 600;
  }

  .membershipPrice {
    font-family: headfont;
    font-size: 24px;
    font-weight: 300;
  }

  .nonMemberPrice {
    font-size: 14px;
  }
}

.disabledCard {
  opacity: .5;
  cursor: not-allowed;
}

.actionsContainer {
  @include breakpoint-down(md) {
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $white;
    border-top: 1px solid $grey;
  }
}

.actions {
  display: flex;
  gap: 1.5rem;

  @include breakpoint-up(md) {
    max-width: 300px;
  }
    .cancel, .update {
      width: 100%;
    }
}

.confirmationModal {
  h2 {
    font-weight: 400;
  }
  .membershipType {
    font-weight: 600;
  }

  .membershipDetails {
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  
    li {
      list-style-type: disc;
    }
  }

  .modalMembershipCard {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid $grey;
    padding: 1rem;

    p {
      margin: .5rem 0;
    }

    .membershipCardTitle {
      font-weight: 400;
      font-size: 16px;
      margin: 0;
    }
    .membershipPrice {
      font-family: headfont;
      font-size: 16px;
      font-weight: 300;
    }
  }

  .termsLink {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
    }
  }

  .modalActions {
    margin-top: 1rem;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    .cancel {
      font-size: 16px;
    }
  }
}
