.iframeModal {
  padding: 0;

  &.error {
    padding: 3rem 2rem;
  }

  iframe {
    width: 100%;
    min-height: 515px;
    border: none;
  }

  .limitError {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  } 
}